import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { useStaticQuery } from 'gatsby';

const CookiePolicy = () => {
  const staticData = useStaticQuery(graphql`
    query SiteCookiePolicyDataQuery {
      site {
        siteMetadata {
          cookiePolicy {
            introduction,
            contents {
              title
              items {
                subTitle
                text
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout color="#0082f5">
      <SEO title="Cookie policy" />
      <h1 className="page-title">Cookie Policy</h1>
      {
        staticData &&
        <div className="tos-container">
          {
            staticData.site.siteMetadata.cookiePolicy.introduction.map((t, i) => (
              <p key={`${i}_`} dangerouslySetInnerHTML={{__html: t}} />
            ))
          }
          {
            staticData.site.siteMetadata.cookiePolicy.contents.map((c, i) => (
              <div key={`${i}_`}>
                <h2>{c.title}</h2>
                {
                  c.items.map((t, i) => (
                    <div key={`${i}_`}>
                      {
                        t.subTitle &&
                        <h3 dangerouslySetInnerHTML={{__html: t.subTitle}} />
                      }
                      {
                        t.text && !t.number &&
                        <p dangerouslySetInnerHTML={{__html: t.text}} />
                      }
                    </div>
                  ))
                }
              </div>
            ))
          }
        </div>
      }
    </Layout>
  );
}

export default CookiePolicy;